<template>
    <div>
        <div class="box">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">{{ $t('invoice.stats_invoices_supplier') }}</h3>
                </div>
                <div class="col-auto">
                    <b-button v-b-toggle.invoiceStat variant="primary" class="mb-0" :disabled="table_busy">{{ $t('invoice.see_stats') }} 
                        <span v-if="table_busy">
                            <font-awesome-icon :icon="['fas', 'spinner']" pulse />
                        </span>
                        <span v-else>
                            <font-awesome-icon :icon="['fal', 'angle-down']" />
                            <font-awesome-icon :icon="['fal', 'angle-up']" />
                        </span>
                    </b-button>
                </div>
            </div>
            <b-collapse v-if="!table_busy" id="invoiceStat">
                <div class="row pt-4">
                    <b-table striped hover :items="stats" :fields="fields_stat">
                        <template #head()="scope">
                            <div>
                                {{ scope.label }}
                            </div>
                        </template>
                    </b-table>
                </div>
                <div class="row mt-2">
                    {{ $t('invoice.charge_pension_horse') }} : {{ charges_pension }}
                </div>
            </b-collapse>
        </div>

        <div class="box">
            <DateRangeV2
                :start.sync="start_date"
                :end.sync="end_date"
                @submit="loadCharges"
                :ShowTodayButton="true"
                :dateSelect="true"
                :periodToShow="['personalise','jour','mois','mois_dernier']"
            />

            <CustomTable
                id_table="horse_charge_supplier"
                ref="horse_charge_supplier"
                :items="charges"
                :busy.sync="table_busy"
                primaryKey="ischargeaccount_id"
                :hide_if_empty="true"
    			:externSlotColumns="extern_slot_columns"
            >
                <template v-slot:[`custom-slot-cell(invoice.suppliersocr_num)`]="{ data }">
                    <a href="" class="" @click.prevent="quickPreview(data.invoice.suppliersocr_pdf_url)">
    				    {{ data.invoice.suppliersocr_num }} <font-awesome-icon :icon="['fas', 'eye']" />
                    </a>
    			</template>
    		</CustomTable>

            <b-modal size="xl" ref="modelPreview" hide-footer @hidden="pdf_url = null">
                <template v-slot:modal-title>
                    {{ $t("action.previsualiser") }}
                </template>

                <iframe v-if="pdf_url != null" :src="pdf_url" height="1000px" width="100%" style="position:relative"></iframe>
                <div v-else>
                    <LoadingSpinner />
                </div>
            </b-modal>
    	</div>
    </div>
</template>

<script type="text/javascript">
    import Invoice from "@/mixins/Invoice.js"
	import Horse from "@/mixins/Horse.js"

	export default {
		name: 'HorseChargeSupplier',
		mixins: [Invoice, Horse],
		props: ['horse_id'],
		data () {
			return {
                start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
                end_date: new Date(),
                charges: [],
                pdf_url: null,
                table_busy: false,
				extern_slot_columns: ['invoice.suppliersocr_num'],
                fields_stat: [
                    {key: 'accounting_account', label: this.getTrad('compta.form.compte_comptable'), sortable: true},
                    {key: 'total_ht', label: this.getTrad('invoice.stat_total_ht'), sortable: true, tdClass: 'text-right'},
                ],
                stats: [],
                charges_pension: null
			}
		},

		methods: {
			async loadCharges() {
				this.table_busy = true
				this.charges = await this.getChargesSupplierByHorse(this.horse_id, this.start_date.toDateInputValue(), this.end_date.toDateInputValue())

                const stats = {}
                this.charges.forEach(charge => {
                    if(!Object.prototype.hasOwnProperty.call(stats, charge.accountingAccount.accountingaccount_id)) {
                        stats[charge.accountingAccount.accountingaccount_id] = {
                            accounting_account: charge.accountingAccount.accountingaccount_full_label,
                            total_ht: 0,
                            currency: charge.suppliersocr_currency
                        }
                    }
                    stats[charge.accountingAccount.accountingaccount_id].total_ht += charge.ischargeaccount_amount
                })

                for(let i in stats) {
                    stats[i].total_ht = await this.priceFormat(stats[i].total_ht, stats[i].currency, true)
                }

                this.stats = Object.values(stats)

                const charges_pension = await this.getChargesPension(this.horse_id, this.start_date, this.end_date)
                this.charges_pension = await this.priceFormat(charges_pension, 'EUR', true)

				this.table_busy = false
			},

            quickPreview(pdf_url) {
                this.$refs.modelPreview.show()
                this.pdf_url = pdf_url
            },
		},

		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2')
		}
	}

</script>